@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lora:wght@400;500;700&family=Poppins:wght@500&family=Rubik:wght@500&display=swap');

$primary: #0496FF;
$gray: #535353;
$black: #000000;
$white: #FFFFFF;

$font-size-mobile: 3.094rem;

$mobile-breakpoint: 768px;
$tablet-min-breakpoint: 769px;
$tablet-max-breakpoint: 1023px;
$desktop-breakpoint: 1024px;


/* 
---------------------------
    Acerca de nosotros
---------------------------
*/
.hero-head.aNosotros {
    padding-left: 3%;
    padding-top: 5%;
}

.aNosotrosTitle {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    // font-size: 3.25rem;
    font-size: 3vw;
    line-height: 4.188rem;
    color: $white;
    width: 40%;
}

.aNosotrosSub {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    // font-size: 1.563rem;
    font-size: 1.6vw;
    line-height: 1.875rem;
    color: $white;
}


.line.aNosotros {
    // width: 4.563rem;
    // height: 0.125rem;
    margin-top: 0.9%;
    margin-left: 1%;
}

.aNosotrosContainer {
    display: flex;
    flex-direction: column;
}

.topImages {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.bottomImages {
    margin-top: -20%;
    display: flex;
    justify-content: space-between;
    width: 59.9%;
}

.imgAnosotros {
    width: 18.5vw;
}

/* 
---------------------------
    ¿Por que BlackMezas
---------------------------
*/ 
.hero-body.pqBM{
    display: flex;
    justify-content: space-around;
}

.pqBM.prop {
    display: flex;
    align-items: center;
}

.pqBM.prop.desc {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.625rem;
    color: $white;
    width: 12.5rem;
    text-align: left;
    margin-left: 5%;
}

.pqBM.pce{
    background-color: $primary;
    width: 48%;
    padding: 4.834rem 8.025rem 15.188rem 4.744rem;
}

.pceTitle {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    // font-size: 2.813rem;
    font-size: 2.7vw;
    line-height: 3.625rem;
    color: $white;
    text-align: left;
    margin-bottom: 8%;
}

.pceDesc {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 1.563rem;
    line-height: 1.875rem;
    color: $white;
    text-align: left;
}

.line.pqbm {
    background-color: $white;
    width: 4.563rem;
    height: 0.125rem;
    margin-top: 2.5%;
}

.sectionTitle.pqbm {
    margin-bottom: 2%;
}

.columns.pqbmTop, .columns.pqbmCenter {
    padding-bottom: 20%;
}

.pqbm.pcs {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    // font-size: 1.563rem;
    font-size: 1.6vw;
    line-height: 1.875rem;
    color: $white;
    margin-bottom: 2%;
    margin-right: 1%;
}

/* 
-------------------------------
    Nuestras instalaciones
-------------------------------
*/ 
.nInstalacionesSub {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    // font-size: 1.563rem;
    font-size: 1.6vw;
    line-height: 1.875rem;
    color: #0D304A;
    margin-bottom: 2%;
    margin-right: 1%;
}

.hero-head.nInstalaciones {
    margin-left: 10%;
    padding-top: 6%;
}

.line.nInstalaciones {
    background-color: #0D304A;
    width: 4.563rem;
    height: 0.125rem;
    margin-top: 1%;
}

.nIntalacionesTitle {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    // font-size: 3.75rem;
    font-size: 3.75vw;
    line-height: 4.813rem;
    color: #0D304A;
    width: 70%;
}

.button.instalaciones, button.instalacionesActive {
    border: 0 solid;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
    text-align: left;
    padding: 2rem 1rem;
    width: 240px;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    // font-size: 1.125rem;
    font-size: 1.125vw;
    line-height: 1.75rem;
    color: $black;
    outline: none;
}

button.instalacionesActive {
    background-color: $primary;
    color: $white;
    border: 0;
    outline: none;
}

.btns.nInstalaciones {
    margin-left: 8%;
    margin-top: -20.8%;
    margin-right: 10%;
}

.imgNinstalaciones{
    // width: 55vw;
    width: 100%;
}

/* 
----------------------
    Que aportamos
----------------------
*/ 
.hero.qAportamosCon {
    padding: 0 10%;
}

.hero-body.qAportamosCon {
    padding: 1%;
}

.columns.qaportamos {
    // display: grid;
    // display: inline-flex;
    gap: 5.313rem;
    color: $black;
}

.column.qaportamos {
    background-color: $white;
    border-radius: 0.625rem;
    // height: 25.063rem;
    height: 41.6vh;
    // padding: 10% 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qaportamosTitle {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    // font-size: 2.188rem;
    font-size: 2.188vw;
    line-height: 2.063rem;
    letter-spacing: 0.006rem;
    padding-bottom: 8%;
}

.qaportamosDesc {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.563rem;
    font-size: 1.5vw;
    line-height: 1.688rem;
    letter-spacing: 0.013rem;
    color: #5C5C5C;
}

.contQaportamos {
    width: 75%;
}

.hero-head.qaportamos {
    padding-top: 6%;
}

.nosotros.qaportamos {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    // font-size: 1.563rem;
    font-size: 1.6vw;
    line-height: 1.875rem;
    color: $white;
    margin-bottom: 2%;
    margin-right: 1%;
}

.qAportamos.qaportamos {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 3.75rem;
    font-size: 3.57vw;
    line-height: 4.813rem;
    color: $white;
}

.line {
    background-color: $white;
    width: 4.563rem;
    height: 0.125rem;
    margin-top: 1%;
}

.sectionTitle {
    display: flex;
}

/* 
-------------------------
    Galeria de fotos
-------------------------
*/

.hero.photoGallery{
    display: flex;
    align-content: center;
    justify-content: center;
}

.hero-head.galeriaFotos {
    position:absolute;
    right: 17vw;
    z-index: 2;
    // margin-left: 43%; 
    margin-top: -30%;
}

.pGalleryTitle {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    // font-size: 3.125rem;
    font-size: 3vw;
    line-height: 4.813rem;
    color: #0D304A;
}

.pGallerySub {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 1.875rem;
    color: #0D304A;
    margin-right: 1%;
}

.line.pGallery {
    background-color: #0D304A;
    width: 4.563rem;
    height: 0.125rem;
    margin-top: 2%;
}

.photoGalleryContainer {
    margin-top: 5%;
}

.nonActiveItem {
    width: 26vw;
    height: 26vw;
    border-left: 30px solid $white;
    border-right: 30px solid $white;
}

.imgPhotoGallery {
    height: 100%;
    width: 100%;
}

.activeItem {
    height: 43vw;
    width: 35vw;
}

.forwardPhotoGallery {
    width: 6vw;
    height: 5vw;
    align-self: center;
    background: transparent;
    border: 0;
    position: absolute;
    right: 47vw;
    margin-top: -18%;
}

.backwardPhotoGallery {
    position: absolute;
    width: 4vw;
    height: 3vw;
    align-self: center;
    background: transparent;
    border: 0;
    right: 53vw;
    margin-top: -18%;
    z-index: 3;
}


/* 
--------------------
    Contactanos
--------------------
*/
.titleContactanos {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    // font-size: 2.5rem;
    font-size: 2.4vw;
    line-height: 3.188rem;
    color: $white;
    margin-bottom: 15%;
}

.descContactanos {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    // font-size: 1.125rem;
    font-size: 1.1vw;
    line-height: 1.875rem;
    color: $white;
    margin-bottom: 10%;
}

.button.contactanos {
    background-color: $primary;
    border: 0;
    border-radius: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.188rem 7.125rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    // font-size: 1.125rem;
    font-size: 1.1vw;
    line-height: 1.75rem;
    color: $white;
}

.contactanosSub {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    // font-size: 1.563rem;
    font-size: 1.6vw;
    line-height: 1.875rem;
    color: $white;
    margin-bottom: 3%;
    margin-right: 2%;
}

.line.contactanos {
    background-color: $white;
    width: 4.563rem;
    height: 0.125rem;
    margin-top: 2.5%;
}

.contactanos.left {
    width: 33.7%;
    margin-left: 7%;
    margin-right: 15%;
}

.hero-body.contactanos {
    display: flex;
    flex-direction: row;
}



@media (max-width: $mobile-breakpoint) {
    /* 
    ---------------------------
        Acerca de nosotros
    ---------------------------
    */
    .hero-head.aNosotros {
        padding-left: 0%;
        padding-top: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .aNosotrosTitle {
        font-size: 2.5rem;
        line-height: 3.125rem;
        width: 90%;
        text-align: center;
    }
    
    .aNosotrosSub {
        font-size: 1.2rem;
    }
    
    
    .line.aNosotros {
        display: none;
    }
    
    .aNosotrosContainer {
        display: flex;
        flex-direction: row;
        margin-top: -15%;
    }
    
    .topImages {
        width: 50%;
        flex-direction: column;
        align-items: center;
    }
    
    .bottomImages {
        margin-top: 25%;
        flex-direction: column;
        align-items: center;
        width: 50%;
    }

    .imgAnosotros {
        width: 90%;
    }

    .topImg {
        margin-bottom: 10%;
    }

    .hid {
        display: none;
    }
    /* 
    ---------------------------
        ¿Por que BlackMezas
    ---------------------------
    */ 
    .hero-body.pqBM{
        flex-direction: column-reverse;
    }

    .pqBM.pce{
        width: 95%;
        padding: 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15%;
    }

    .line.pqbm {
        width: 0rem;
    }

    .pceTitle {
        font-size: 2.3rem;
        line-height: 3rem;
        text-align: center;
        margin-bottom: 8%;
    }

    .pceDesc {
        font-size: 1.14rem;
        line-height: 1.875rem;
        text-align: center;
    }

    .columns.pqbmTop, .columns.pqbmCenter {
        padding-bottom: 5%;
    }

    .pqbm.pcs {
        font-size: 1.2rem;
        margin-bottom: 4%;
        margin-right: 0%;
    }

    .pqBM.prop {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pqBM.prop.desc {
        font-size: 1.14rem;
        line-height: 1.625rem;
        width: 12.5rem;
        text-align: center;
        margin-left: 0%;
        margin-top: 5%;
    }

    .icnPQBM {
        width: 2.5rem;
    }

    /* 
    -------------------------------
        Nuestras instalaciones
    -------------------------------
    */ 
    .nInstalacionesSub {
        font-size: 1.2rem;
        margin-bottom: 8%;
        margin-top: 5%;
        margin-right: 0%;
        text-align: center;
    }
    
    .line.nInstalaciones {
        display: none;
    }
    
    .nIntalacionesTitle {
        font-size: 2.5rem;
        line-height: 3rem;
        text-align: center;
        width: 80%;
    }

    .nInstalacionesG {
        display: flex;
        flex-direction: column;
    }
    
    .button.instalaciones, button.instalacionesActive {
        justify-content:center;
        text-align: center;
        padding: 2rem 1rem;
        font-size: 1.2rem;
    } 
    
    .btns.nInstalaciones {
        margin-left: 0%;
        margin-top: -5%;
        margin-right: 0%;
    }
    
    .imgNinstalaciones{
        margin-top: 15%;
        width: 100%;
    }

    .hero-head.nInstalaciones {
        margin-left: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }


    /* 
    ----------------------
        Que aportamos
    ----------------------
    */ 
    .qAportamos.qaportamos {
        font-size: 2.5rem;
        text-align: center;
    }

    .hero.qAportamosCon {
        padding: 0 0;
    }

    .nosotros.qaportamos {
        font-size: 1.2rem;
        margin-right: 5%;
    }

    .line {
        margin-top: 10%;
        width: 0%;
        display: none;
    }

    .hero-head.qaportamos {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .columns.qaportamos {
        gap: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .column.qaportamos {
        width: 85%;
        height: 200vw;
        // height: 190vh;
        // width: 100%;
        padding-bottom: 18%;
        padding-top: 18%;
    }

    .qaportamosTitle {
        font-size: 1.6rem;
        // font-size: 6vw;
        line-height: 2.063rem;
        letter-spacing: 0.006rem;
        padding-bottom: 8%;
    }
    
    .qaportamosDesc {
        font-size: 1.14rem;
        // font-size: 3vw;
        line-height: 1.6rem;
        letter-spacing: 0.013rem;
        color: #5C5C5C;
    }

    .iconQaportamos {
        width: 3.125rem;
    }

    .contQaportamos {
        width: 90%;
    }

    /* 
    -------------------------
        Galeria de fotos
    -------------------------
    */

    .hero.photoGallery{
        justify-content:flex-start;
        height: 110vh;
    }

    .hero-head.galeriaFotos {
        position: static; 
        margin-top: 10%;
    }

    .pGalleryTitle {
        font-size: 2.5rem;
        // font-size: 2.5vw;
        line-height: 3rem;
        text-align: center;
    }

    .pGallerySub {
        font-size: 1.2rem;
        margin-right: 0%;
        margin-bottom: 2%;
        text-align: center;
        width: 100%;
    }

    .line.pGallery {
        display: none;
    }

    .photoGalleryContainer {
        margin-top: 15%;
        height: 100vh;
    }

    .nonActiveItem {
        height: 60vh;
        width: 80vw;
        border-left: 0;
        border-right: 0;
    }

    .imgPhotoGallery {
        height: 100%;
        width: 100%;
    }

    .activeItem {
        height: 60vh;
        width: 80vw;
    }

    .forwardPhotoGallery {
        width: 26vw;
        height: 25vw;
        align-self: center;
        background: transparent;
        border: 0;
        position: absolute;
        right: 28vw;
        margin-top: 140%;
    }
    
    .backwardPhotoGallery {
        position: absolute;
        width: 18vw;
        height: 17vw;
        align-self: center;
        background: transparent;
        border: 0;
        right: 54vw;
        margin-top: 140%;
        z-index: 3;
    }


    /* 
    --------------------
        Contactanos
    --------------------
    */
    .contactanos.left {
        width: 100%;
        margin-left: 0%;
        margin-right: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .titleContactanos {
        font-size: 2.5rem;
        margin-bottom: 15%;
        text-align: center;
    }

    .descContactanos {
        font-size: 1.14rem;
        text-align: center;
    }

    .line.contactanos {
        width: 0rem;
    }

    .contactanosSub {
        font-size: 1.2rem;
        margin-bottom: 3%;
        margin-right: 0%;
    }

    .patternDiv{
        display: none;
    }

    .button.contactanos {
        font-size: 4vw;
    }

}


@media (min-width:380px) and (max-width: 920px) and (orientation:landscape) {
    /* 
    ---------------------------
        Acerca de nosotros
    ---------------------------
    */
    .hero-head.aNosotros {
        padding-left: 6%;
        padding-top: 5%;
    }
    
    .aNosotrosTitle {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        // font-size: 3.3vw;
        line-height: 2rem;
        color: $white;
        width: 40%;
    }
    
    .aNosotrosSub {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 1.8vw;
        line-height: 1.875rem;
        color: $white;
    }
    
    .line.aNosotros {
        width: 4rem;
        height: 0.05rem;
        margin-top: 2%;
        margin-left: 1%;
    }
    
    .aNosotrosContainer {
        display: flex;
        flex-direction: column;
        margin-top: -3%;
    }

    .imgAnosotros {
        width: 17.3vw;
    }

    /* 
    ---------------------------
        ¿Por que BlackMezas
    ---------------------------
    */ 
    .pqBM.prop.desc {
        font-size: 1rem;
        line-height: 1.625rem;
        color: $white;
        width: 12.5rem;
        text-align: left;
        margin-left: 5%;
    }

    .icnPQBM {
        width: 2.5rem;
    }

    .pqBM.pce{
        background-color: $primary;
        width: 40%;
        padding: 2.5rem 2rem 2.5rem 2rem;
        margin-left: 3%;
    }

    .pceTitle {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        // font-size: 2.813rem;
        line-height: 2rem;
        color: $white;
        text-align: left;
        margin-bottom: 5%;
    }

    .pceDesc {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 2vw;
        line-height: 1.5rem;
        color: $white;
        text-align: left;
    }

    .line.pqbm {
        background-color: $white;
        // width: 4.563rem;
        // height: 0.125rem;
        width: 3rem;
        height: 0.05rem;
        margin-top: 7%;
        margin-left: 2%;
        // display: none;
    }

    .sectionTitle.pqbm {
        margin-bottom: 2%;
    }

    .columns.pqbmTop, .columns.pqbmCenter {
        padding-bottom: 5%;
    }

    .pqbm.pcs {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 1.8vw;
        line-height: 1.875rem;
        color: $white;
        margin-bottom: 2%;
        margin-right: 1%;
    }

    /* 
    -------------------------------
        Nuestras instalaciones
    -------------------------------
    */ 
    .nInstalacionesSub {
        font-size: 1.8vw;
        margin-bottom: 1%;
        margin-right: 1%;
    }

    .hero-head.nInstalaciones {
        margin-left: 10%;
        padding-top: 6%;
    }

    .line.nInstalaciones {
        background-color: #0D304A;
        width: 3.5rem;
        height: 0.05rem;
        margin-top: 2.2%;
    }

    .nIntalacionesTitle {
        font-family: 'Lora';
        font-style: normal;
        font-weight: 700;
        // font-size: 3.75rem;
        line-height: 2.5rem;
        color: #0D304A;
        width: 70%;
    }

    .button.instalaciones, button.instalacionesActive {
        border: 0 solid;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:flex-start;
        text-align: left;
        padding: 2rem 1rem;
        width: 180px;

        font-size: 1.6vw;
        line-height: 1.75rem;
        color: $black;
        outline: none;
    }

    button.instalacionesActive {
        background-color: $primary;
        color: $white;
        border: 0;
        outline: none;
    }

    .btns.nInstalaciones {
        margin-left: 5%;
        margin-top: 0%;
        margin-right: 10%;
    }

    .imgNinstalaciones{
        // width: 55vw;
        width: 100%;
    }

    .hero-body.nInstalacionesG {
        margin-top: -2%;
    }

    /* 
    ----------------------
        Que aportamos
    ----------------------
    */ 
    .hero.qAportamosCon {
        padding: 0 10%;
    }

    .hero-body.qAportamosCon {
        padding: 1%;
    }

    .qAportamosCC {
        display: flex;
        justify-content: center;
    }

    .columns.qaportamos {
        gap: 2rem;
        width: 100%;
    }

    .column.qaportamos {
        background-color: $white;
        // border-radius: 0.625rem;
        height: 55vh;
    }

    .qaportamosTitle {
        font-size: 2.4vw;
        padding-bottom: 3%;
    }

    .qaportamosDesc {
        font-size: 1.6vw;
        line-height: 1.2rem;
    }

    .contQaportamos {
        width: 75%;
    }

    .hero-head.qaportamos {
        padding-top: 6%;
    }

    .nosotros.qaportamos {
        font-size: 1.8vw;
        line-height: 1.875rem;
        margin-bottom: 0%;
        margin-right: 1%;
    }

    .qAportamos.qaportamos {
        margin-top: -1%;
    }

    .line {
        background-color: $white;
        width: 3.5rem;
        height: 0.05rem;
        margin-top: 2.3%;
    }

    .iconQaportamos {
        width: 4vw;
    }

    /* 
    -------------------------
        Galeria de fotos
    -------------------------
    */

    .hero.photoGallery{
        display: flex;
        align-content: center;
        justify-content: center;
    }

    .hero-head.galeriaFotos {
        right: 19vw;
    }

    .pGalleryTitle {
        font-size: 3vw;
        margin-top: -6%;
    }

    .pGallerySub {
        font-size: 1.8vw;
        margin-right: 2%;
        margin-top: 1%;
    }

    .line.pGallery {
        background-color: #0D304A;
        width: 3.5rem;
        height: 0.05rem;
        margin-top: 5.5%;
    }

    .photoGalleryContainer {
        margin-top: 5%;
    }

    .nonActiveItem {
        width: 30vw;
        height: 28vw;
    }

    .activeItem {
        height: 43vw;
        width: 35vw;
    }

    .forwardPhotoGallery {
        width: 7vw;
        height: 6vw;
        align-self: center;
        background: transparent;
        border: 0;
        position: absolute;
        right: 48vw;
        margin-top: -21%;
    }

    .backwardPhotoGallery {
        position: absolute;
        width: 5vw;
        height: 4vw;
        align-self: center;
        background: transparent;
        border: 0;
        right: 54.5vw;
        margin-top: -21%;
        z-index: 3;
    }

    /* 
    --------------------
        Contactanos
    --------------------
    */
    .titleContactanos {
        margin-bottom: 7%;
    }

    .descContactanos {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        // font-size: 1.125rem;
        font-size: 1.6vw;
        line-height: 1.875rem;
        color: $white;
        margin-bottom: 10%;
    }

    .button.contactanos {
        background-color: $primary;
        border: 0;
        border-radius: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2.188rem 4.5rem;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        // font-size: 1.125rem;
        font-size: 1.6vw;
        line-height: 1.75rem;
        color: $white;
    }

    .contactanosSub {
        font-size: 1.8vw;
        margin-bottom: 3%;
        margin-right: 2%;
    }

    .line.contactanos {
        background-color: $white;
        width: 3.5rem;
        height: 0.05rem;
        margin-top: 6.5%;
    }

    .contactanos.left {
        width: 33.7%;
        margin-left: 6%;
        margin-right: 15%;
    }

    .hero-body.contactanos {
        display: flex;
        flex-direction: row;
    }

    .patternContactanos {
        width: 35vw;
    }
}