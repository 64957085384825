@import "~bulma/bulma";
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&family=Poppins:wght@500&family=Rubik:wght@500&display=swap');

$primary: #0496FF;
$gray: #535353;
$black: #000000;
$white: #FFFFFF;

$font-size-web: 4.5rem;
$font-family-web: 'Lora';
$title-line-height-web: 5.75rem;

$font-size-mobile: 3.094rem;
$title-line-height-mobile: 3.953rem;

$mobile-breakpoint: 768px;
$tablet-min-breakpoint: 769px;
$tablet-max-breakpoint: 1023px;
$desktop-breakpoint: 1024px;


.hero.paises{
    display: flex;
    justify-content: center;
}

.ubicaciones{
    width: 100%;
    text-align: center;
}

.hero-body.ubicaciones {
    position: relative;
}

.hero-body.two {
    background: rgba(255, 255, 255, 0.7);
    width: 100%;
    height: 100%;
    position: absolute;
}

.ubicaciones {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.title.ubicaciones {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11.25rem;
    line-height: 15.625rem;
    color: rgba(0, 0, 0, 0.1);
}

.paises {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 65%;
    font-size: 4.375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.columns.flur {
    // display: grid;
    // display: inline-flex;
    // flex-wrap: wrap;
    gap: 13.438rem;
}

.columns.colgua {
    // display: grid;
    // display: inline-flex;
    // flex-wrap: wrap;
    gap: 8.127rem;
}

.proximamente {
    margin-top: 7%;
    font-size: 2.125rem;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 600;
    position: absolute;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
}

.pais.florida:hover,
.pais.colombia:hover,
.pais.uruguay:hover,
.pais.guatemala:hover,
.pais.argentina:hover {
    font-weight: 600;
    text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.8);
    position:sticky;
    z-index: 2;
}

.imgPais {
    position: absolute;
    width: 25rem;
}

.column.cHover{
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: $mobile-breakpoint) {       
    .title.ubicaciones {
        font-size: 7rem;
    }
    
    .paises {
        font-size: 3.375rem;
        justify-content: center;
    }
    
    .columns.flur {
        gap: 0;
    }
    
    .columns.colgua {
        gap: 0;

    }
    
    .proximamente {
        margin-top: 25%;
        font-size: 2rem;
    }
    
    .imgPais {
        width: 21.875rem;
    }
}


@media (min-width:380px) and (max-width: 920px) and (orientation:landscape) {
    .title.ubicaciones {
        font-size: 7rem;
        // margin-top: 50%;
        line-height: 12rem;
    }
    
    .paises {
        font-size: 3.375rem;
        justify-content: center;
    }
    
    .columns.flur {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-top: 20%;
    }
    
    .columns.colgua {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .columns.arg {
        padding-bottom: 8%;
    }
    
    .proximamente {
        margin-top: 11%;
        font-size: 2rem;
    }
    
    .imgPais {
        width: 20rem;
    }

    .hero.paises {
        height: 150%;
    }

}