@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lora:wght@400;500;700&family=Poppins:wght@500;700&family=Rubik:wght@500&display=swap');

$primary: #0496FF;
$gray: #535353;
$black: #000000;
$white: #FFFFFF;

$mobile-breakpoint: 768px;
$tablet-min-breakpoint: 769px;
$tablet-max-breakpoint: 1023px;
$desktop-breakpoint: 1024px;



.hero-body.contactanosSD {
    display: flex;
    flex-direction: column;
}

.contactanos.titles {
    margin-top: 3%;
    text-align: center;
    width: 100%;
    margin-bottom: 3%;
}

.title.contactanos {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 3.75rem;
    line-height: 4rem;
    text-align: center;
    color: #FFFFFF;

}

.subtitle.contactanos {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.563rem;
    line-height: 1.75rem;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.7;
}

.form.containerF {
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 63vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 2% 5%;
}

.form.top {
    display: flex;
    width: 100%;
    justify-content:space-between;
}

.form.bottom {
    display: flex;
    width: 100%;
    justify-content:space-between;
}


.button.envMensaje {
    background-color: $primary;
    border: 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.188rem 4rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: $white;
    margin-top: 5%;
}

.field, .selectElement {
    // width: 438px;
    width: 25.5vw;
}

.input, .selectElement {
    padding: 5% 2%;
}

.field.message_ {
    width: 100%;
}

.label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 2rem;
}

.select {
    z-index: 0;
}


@media (max-width: $mobile-breakpoint) {
    .title.contactanos{
        font-size: 2.5rem;
    }

    .subtitle.contactanos{
        font-size: 1.3rem;
    }

    .form.top {
        flex-direction: column;
        // background-color: #0496FF;
    }
    .field, .selectElement {
        width: 72vw;
    }

    .form.containerF {
        width: 80vw;
        height: 70vh;
    }

    .label {
        font-size: 0.8rem;
        line-height: 1.5rem;
    }

    .button.envMensaje {
        padding: 1.5rem 2rem;
    
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.75rem;
    }
}


@media (min-width:380px) and (max-width: 920px) and (orientation:landscape) {
    // .hero-body.contactanosSD {
    //     display: flex;
    //     flex-direction: column;
    // }
    
    .contactanos.titles {
        margin-top: 0%;
        // text-align: center;
        // width: 100%;
        margin-bottom: 3%;
    }
    
    .title.contactanos {
        font-size: 5vw;
        line-height: 2rem;
    
    }
    
    .subtitle.contactanos {
        font-size: 2.5vw;
        line-height: 1.75rem;
    }
    
    .form.containerF {
        background-color: #FFFFFF;
        border-radius: 12px;
        width: 65vw;
        height: 95vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 2% 5%;
    }
    
    .form.top {
        display: flex;
        width: 100%;
        justify-content:space-between;
    }
    
    .form.bottom {
        display: flex;
        width: 100%;
        justify-content:space-between;
    }
    
    
    .button.envMensaje {
        background-color: $primary;
        border: 0;
        border-radius: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.5rem 2rem;
    
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5vw;
        line-height: 1.75rem;
        color: $white;
        margin-top: 2%;
    }
    
    .field, .selectElement {
        width: 25.5vw;
    }
    
    .input, .selectElement {
        padding: 5% 2%;
    }
    
    .field.message_ {
        width: 100%;
    }

    
    .label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1.5vw;
        line-height: 0.5rem;
    }

}