@import "~bulma/bulma";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Lora:wght@400;500;700&family=Poppins:wght@500;700&family=Raleway&family=Rubik:wght@500&display=swap');

$primary: #0496FF;
$gray: #535353;
$black: #000000;
$white: #FFFFFF;


$font-size-web: 4.3vw;
$font-family-web: 'Lora';
$title-line-height-web: 5.75rem;

$font-size-mobile: 3.094rem;
$title-line-height-mobile: 3.953rem;

$map: './map.svg';

// $mobile-breakpoint: 768px;
// $tablet-min-breakpoint: 769px;
// $tablet-max-breakpoint: 1023px;
// $desktop-breakpoint: 1024px;

$mobile-breakpoint: 767px;
$tablet-min-breakpoint: 992px;
// $tablet-max-breakpoint: 1023px;
$desktop-breakpoint: 1024px;

// $breadcrumb-item-active-color: $primary;


/*
-------------
    Menu
-------------
*/

.hamburger {
    position: fixed;
    right: 5%;
    top: 5%;
    z-index: 2;
}

.menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ulItem {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 3.58vw;
    line-height: 5.125rem;
    text-align: center;
    color: $white;

    display: flex;
    justify-content: space-evenly;
    width: 93%;
}

.is-active {
    color: $primary;
    font-weight: 700;
}

.liItem::after {
    content: " /";
    color: $white;
    font-weight: 500;
}

.liItem:last-child:after {
    content: "";
}

.linkTo {
    color: $white;
}



// -----

.hero.home {
    background-image: url('./home-bg.png');
    object-fit: cover;
    background-position: right;
    background-position: 80% 60%;
}

.is-black {
    background-color: $black;
}

.is-white {
    background-color: $white;
}


.slogan {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 6.25rem;
    line-height: 7.313rem;
    text-align: center;
    margin-top: -4%;
}

/*
-----------------------------------
    Una perspectiva continental
-----------------------------------
*/

.is-image {
    background-image: url($map);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.column.persCon {
    margin-top: -3%;
}


.title.perspCon {
    color: $black;
    font-family: $font-family-web;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-web;
    line-height: $title-line-height-web;
    margin-top: 5%;
    // margin-left: 9%;
    text-align: center;
}

.columns.perspCon {
    // display: grid;
    display: flex;
    gap: 2.494rem;
}

.column.persCon {
    height: 38.818rem;
    // height: 38.818vw;
    width: 29.093rem;
    color: $white;
    background-color: $black;
    font-family: $font-family-web;
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.563rem;
}

.persCon-aligment{
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}

.column.persCon p{
    width: 14.375rem;
}

.maskgroup {
    display: flex;
    flex-direction: row-reverse;
    z-index: 2;
}

.blur-layer {
    background: rgba(112, 112, 112, 0.3);
    height: 100%;
    backdrop-filter: blur(48px);
}

/*
------------------------------
    La formula correcta
------------------------------
 */
.title.formcorr {
    font-family: $font-family-web;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-web;
    line-height: $title-line-height-web;
    width: 21.125rem;
    margin-left: 7rem;
}

.formCorrCon {
    display: flex;
}

.formCorrNum {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-size: 3.75rem;
    line-height: 4.438rem;
    color: $primary;
}

.formCorrTit {
    font-family: 'Lora';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875rem;
    line-height: 2.375rem;
    color: $white;
    padding-top: 2%;
    padding-left: 1.5%;
}

.formCorrDesc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-indent: 2px;
    width: 22.125rem;
}

.columns.formCorrTop {
    padding-bottom: 2.5rem;
}

.columns.formCorrBottom {
    padding-top: 2.5rem;
}

/*
----------------------------
    Propuesta de valor
----------------------------
 */
.hero-body.pvalor {
    display: flex;
    flex-direction: column;
}

.title.pvalor {
    font-family: $font-family-web;
    font-style: normal;
    font-weight: 700;
    font-size: $font-size-web;
    line-height: $title-line-height-web;
    text-align: center;
    margin-top: 4.375rem;
    margin-bottom: 1.875rem;
}

.columns.pvalor {
    display: grid;
    gap: 4.188rem;
}

.columns.pvalor.top {
    margin-bottom: 4.188rem;
}

.column.pvalor {
    width: 25.313rem;
    height: 19.75rem;
    background-color: $black;
    display: flex;
    justify-content: center;
}

.pvalorTit {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
    color: $white;
    margin-top: 1.438rem;
    margin-bottom: 0.75rem;
}

.pvalorDesc {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: $white;
    opacity: 0.7;
}

.imgMedal, .imgMarker {
    margin-left: -1.5%;
}

.pvalorItems {
    width: 20.255rem;
    margin-top: 1.875rem;
}
 


@media (max-width: $mobile-breakpoint) {
    /*
    -------------
        Menu
    -------------
    */
    .ulItem {
        font-size: 2.813rem;
        flex-direction: column;
        height: 55%;
    }
    
    .liItem::after {
        content: "";
    }
    
    // -------

    .slogan {
        font-size: 2rem;
        line-height: 2.34rem;
    }

    /*
    ---------------
        Roadmap
    ---------------
    */
    .hero.roadmap {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /*
    -----------------------------------
        Una perspectiva continental
    -----------------------------------
    */
    .hero-body {
        overflow: hidden;
    }

    .title.perspCon {
        font-size: 3.094rem;
        line-height: 3.953rem;
        margin-left: 3%;
    }

    .columns.perspCon {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
    }

    .column.persCon {
        height: 68vh;
        // height: 100vw;
        // width: 22.875rem;
        width: 22rem;
        font-size: 1.4rem;
        line-height: 2.563rem;
        // position: absolute;
    }


    .container.perspCon {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .blur-layer {
        // padding-bottom: 20%;
        height: 65vh;
    }


    // .maskgroup{
    //     display: none;
    // }

    /*
    ------------------------------
        La formula correcta
    ------------------------------
    */
    .hero-body.formCorrMobile {
        display: flex;
        flex-direction: column;
    }

    .title.formcorr {
        font-size: $font-size-mobile;
        line-height: $title-line-height-mobile;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 15%;
        text-align: center;
    }

    .formCorrNum {
        font-size: 2.5rem;
        line-height: 4.438rem;
    }
    
    .formCorrTit {
        font-size: 1.125rem;
        line-height: 2.375rem;
        padding-top: 3%;
    }

    .columns.formCorrTop {
        padding-bottom: 0rem;
    }
    
    .columns.formCorrBottom {
        padding-top: 0rem;
    }

    /*
    ----------------------------
        Propuesta de valor
    ----------------------------
    */
    .column.pvalor {
        width: 22rem;
        height: 19.75rem;
    }

    .title.pvalor {
        font-size: $font-size-mobile;
        line-height: $title-line-height-mobile;
        margin-top: 10%;
        margin-bottom: 3%;
        margin-left: 3%;
    }

    .columns.pvalor {
        gap: 1.5rem;
    }

    .columns.pvalor.top {
        margin-bottom: 2.3rem;
    }
}


@media (min-width:380px) and (max-width: 920px) and (orientation:landscape) {
    /*
    -------------
        Menu
    -------------
    */
    .ulItem {
        font-size: 2.813rem;
        flex-direction: column;
        height: 55%;
    }
    
    .liItem::after {
        content: "";
    }
    
    // -------

    .slogan {
        font-size: 3.5rem;
        line-height: 3.5rem;
    }

    /*
    ---------------
        Roadmap
    ---------------
    */
    .hero.roadmap {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    /*
    -----------------------------------
        Una perspectiva continental
    -----------------------------------
    */
    .hero-body {
        overflow: hidden;
    }

    .title.perspCon {
        font-size: 3.094rem;
        line-height: 3.953rem;
        margin-left: 3%;
    }

    .column.persCon {
        height: 70%;
        width: 80%;
        font-size: 1.4rem;
        line-height: 2.563rem;
    }

    .columns.perspCon {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        margin-top: 2%;
    }

    .maskgroup {
        height: 15vh;
        display: flex;
        flex-direction: row-reverse;
        z-index: 2;
        // position: absolute;
        right: 0;
        // display: none;
    }

    .persCon-aligment{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-top: -10%
    }

    .column.persCon p{
        width: 80%;
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /*
    ------------------------------
        La formula correcta
    ------------------------------
    */
    .hero-body.formCorrMobile {
        display: flex;
        flex-direction: column;
    }

    .title.formcorr {
        font-size: $font-size-mobile;
        line-height: $title-line-height-mobile;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 15%;
        text-align: center;
    }

    .formCorrNum {
        font-size: 2.5rem;
        line-height: 4.438rem;
    }
    
    .formCorrTit {
        font-size: 1.125rem;
        line-height: 2.375rem;
        padding-top: 3%;
    }

    .columns.formCorrTop {
        padding-bottom: 0rem;
        gap: 5%;
    }
    
    .columns.formCorrBottom {
        padding-top: 0rem;
        gap: 5%
    }


    /*
    ----------------------------
        Propuesta de valor
    ----------------------------
    */
    .column.pvalor {
        width: 23rem;
        height: 19.75rem;
    }

    .title.pvalor {
        font-size: $font-size-mobile;
        line-height: $title-line-height-mobile;
        margin-top: 10%;
        margin-bottom: 3%;
        margin-left: 3%;
    }

    .columns.pvalor {
        gap: 1.5rem;
    }

    .columns.pvalor.top {
        margin-bottom: 2.3rem;
    }

}



// @media (min-width: $tablet-min-breakpoint) and (max-width: $tablet-max-breakpoint) {
//     .slogan {
//         font-size: 5.3rem;
//         line-height: 6.2rem;
//     }

//     .title.perspCon {
//         font-size: 3.094rem;
//         line-height: 3.953rem;
//         margin-left: 3%;
//     }
    

//     .column.persCon {
//         height: 32.6rem;
//         width: 15rem;
//         color: $white;
//         background-color: $black;
//         font-family: 'Lora';
//         font-style: normal;
//         font-weight: 400;
//         font-size: 1.7rem;
//         line-height: 2.563rem;
//     }
// }