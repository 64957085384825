@import "~bulma/bulma";
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500;700&family=Poppins:wght@500&family=Rubik:wght@500&display=swap');

$gray: #535353;
$white: #FFFFFF;

// $mobile-breakpoint: 768px;
// $tablet-min-breakpoint: 769px;
// $tablet-max-breakpoint: 1023px;
// $desktop-breakpoint: 1024px;

$mobile-breakpoint: 767px;
$tablet-min-breakpoint: 992px;
// $tablet-max-breakpoint: 1023px;
$desktop-breakpoint: 1024px;

/*
----------------
    Footer
----------------
*/

.footer.footCont{
    height: 4.375rem;
    background-color: $gray;
    margin: 0;
    padding: 0;
}

.footerContent {
    height: 100%;
    color: $white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: 'Lora';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin-right: 2%;
}

.arr {
    padding-right: 28.75rem;
}

.socialIcons{
    display: flex;
    justify-content: space-between;
    width: 7%;
    
}

@media (max-width: $mobile-breakpoint) {
    .footer.footCont{
        height: 7rem;
        background-color: $gray;
        margin: 0;
        padding: 0;
    }
    
    .footerContent {
        display: flex;
        flex-direction: column;
        font-size: 0.938rem;
        line-height: 1.875rem;
        margin-right: 0;
    }

    .arr {
        padding-right: 0rem;
        margin-bottom: 3%;
    }
    
    .socialIcons{
        width: 30%;
        margin-bottom: 5%;
    }

    .instaIcon, .faceIcon, .youtubeIcon {
        width: 25px;
    }
}


@media (min-width:380px) and (max-width: 920px) and (orientation:landscape) {
    .footer.footCont{
        height: 4.375rem;
        background-color: $gray;
        margin: 0;
        padding: 0;
    }
    
    .footerContent {
        height: 100%;
        color: $white;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: 'Lora';
        font-style: normal;
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.875rem;
        margin-right: 4%;
    }
    
    .arr {
        // padding-right: 28.75rem;
        padding-right: 14%;
    }
    
    .socialIcons{
        display: flex;
        justify-content: space-between;
        width: 13%;
        
    }
}